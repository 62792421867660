import React from 'react';
import { Link } from 'react-router-dom';

// Components
import withApi from '../../hoc/withApi';
import Loader from '../../components/Loader';
import Window from '../../components/Window';
import Content from '../../components/Content';
import Shutter from '../../components/Shutter';
import { RotateCcw } from 'react-feather';

export default withApi('GetPage/home', class Home extends React.Component {
    constructor() {
        super();
        this.state = {
            data: null,
            dataLoaded: null,
        };
    }

    componentDidMount() {
        this.setState({
            data: this.props.data,
            dataLoaded: this.props.dataLoaded,
        });
    }

    render() {
        const { siteData } = this.props;
        const { data, dataLoaded } = this.state;

        if (dataLoaded) {
            const background = data.Image || siteData.Background;

            const footer = (
                <div
                    className="opp-footer--showreel">
                    <Link
                        to={{
                            pathname: "/showreel",
                            // state: {transition: 'slide_up'}
                        }}>View Showreel</Link>
                </div>
            );

            return (
                <Window
                    footer={footer}>
                    <main
                        className="opp-main">
                        <Shutter />
                        <div 
                            className="opp-home">
                            <div 
                                className="opp-content--text">
                                <h1 
                                    className="opp-content--heading">{siteData.Title}</h1>
                                <Content
                                    html={data.Content} />
                            </div>
                            <Link
                                to="/contact"
                                className="opp-cta">Book us now</Link>
                        </div>
                        <Link
                            to={{
                                pathname: "/projects",
                                // state: {transition: 'slide_right'}
                            }}
                            className="opp-projects--button">View{' '}
                            <br />Projects
                            <img src="assets/right.svg" alt="Right Arrow" />
                        </Link>
                    </main>
                </Window>
            );
        }
        else {
            return <Loader />;
        }
    }
});