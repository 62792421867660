import React from 'react';

export default function Logo(props) {
    const { className } = props;
    return (
        <svg
            className="opp-mouse"
            width="26"
            height="38"
            viewBox="0 0 26 38"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <rect
                x="1"
                y="1"
                width="24"
                height="36"
                rx="12"
                stroke="white"
                strokeWidth="2" />
            <path
                d="M13 6V9"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round" />
        </svg>
    );
}