import React from 'react';

export default function Logo (props) {
    const { className } = props;
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 73 73">
            <g id="Symbol_1_1" data-name="Symbol 1 – 1" transform="translate(6010 459)">
                <circle id="Ellipse_1" data-name="Ellipse 1" className="opp-logo--o" cx="35.5" cy="35.5" r="35.5" transform="translate(-6009 -458)"
                />
                <path id="Path_1" data-name="Path 1" className="opp-logo--p1" d="M-5985.447-410.6v-20.961s9.651-1.381,9.733,5.1-9.733,5.1-9.733,5.1"
                    transform="translate(18)" />
                <path id="Path_2" data-name="Path 2" className="opp-logo--p2" d="M-5985.447-410.6v-20.961s9.651-1.381,9.733,5.1-9.733,5.1-9.733,5.1"
                />
            </g>
        </svg>
    );
}