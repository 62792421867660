import React from 'react';

import withApi from '../../hoc/withApi';

// Components
import Content from '../../components/Content';
import Loader from '../../components/Loader';
import Window from '../../components/Window';
import Shutter from '../../components/Shutter';
import Social from '../../components/Social';

import _ajax from '../../utils/_ajax';

export default withApi('GetPage/contact', class Contact extends React.Component {
    constructor() {
        super();
        this.state = {
            data: null,
            dataLoaded: null,
            success: false,
        };
    }

    componentDidMount() {
        this.setState({
            data: this.props.data,
            dataLoaded: this.props.dataLoaded,
        });
    }

    render() {
        const { data, dataLoaded, success } = this.state;
        if (dataLoaded) {
            return (
                <Window>
                    <main
                        className="opp-main">
                        <Shutter />
                        <div
                            className="opp-content">
                            <div
                                className="opp-content--text">
                                <h1
                                    className="opp-content--heading">{data.Title}</h1>
                                <Content
                                    className="opp-content--blurb"
                                    html={data.Content} />
                                <Social data={this.props.siteData} />
                            </div>
                        </div>
                        
                    </main>
                </Window>
            );
        }
        else {
            return <Loader />;
        }
    }
});